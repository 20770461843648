<template>
  <div class="flex flex-col justify-center relative">
    <!-- Hero -->
    <div class="h-[calc(100vh-2.5rem)] relative">
      <div
        class="relative h-full flex flex-col justify-center text-center max-w-6xl mx-auto z-[5]"
      >
        <div class="p-10 flex flex-col justify-center text-slate-50">
          <div>
            <h1
              class="font-extrabold tracking-tighter text-5xl lg:text-7xl uppercase"
            >
              Let's Build What's Next
            </h1>
            <p class="mt-4 text-xl lg:text-2xl tracking-wider">
              Combining thoughtful innovation and great people resulting in
              mission success.
            </p>
          </div>
        </div>
      </div>
      <video
        id="background-video"
        autoplay
        loop
        muted
        playsinline
        class="absolute top-0 object-cover brightness-[.33] h-full w-screen"
      >
        <source
          src="@/shared/assets/network_finger_1080p.mp4"
          type="video/mp4"
          data-quality="high"
        />
        <source
          src="@/shared/assets/network_finger_720p.mp4"
          type="video/mp4"
          data-quality="medium"
        />
        <source
          src="@/shared/assets/network_finger_480p.mp4"
          type="video/mp4"
          data-quality="low"
        />
        Your browser does not support video
      </video>
      <div
        class="absolute inset-x-0 bottom-0 flex justify-center items-center bg-gradient-to-t from-slate-950 h-32 z-[5]"
      >
        <a
          type="button"
          href="#about"
          class="relative focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 text-slate-50 flex items-center pointer-events-auto"
          @click="navigateToId"
        >
          <ChevronDownIcon class="h-10" />
        </a>
      </div>
    </div>
    <!-- About -->
    <div id="about" class="py-24 px-6 lg:px-10">
      <section class="max-w-7xl mx-auto">
        <p
          class="text-3xl lg:text-4xl font-light text-white leading-normal lg:leading-normal fade-in"
        >
          At Woven, we simultaneously tailor bespoke solutions to our mission
          partners' needs while leveraging our wealth of experience. Our
          reputation is known for delivering powerful outcomes for our clients,
          resulting in success and the ultimate client satisfaction.
        </p>
        <!-- <div
          class="mt-24 text-2xl lg:text-3xl font-light leading-normal lg:leading-normal flex justify-center"
        >
          <div class="max-w-[680px] fade-in">
            <p>
              Together we are building a
              <span class="font-semibold text-woven-light-green"
                >community</span
              >
              of experts who embrace change, reshaping the industry through our
              desire to build
              <span class="font-semibold text-woven-light-green"
                >excellence</span
              >, and fostering collective
              <span class="font-semibold text-woven-light-green">growth</span>
              where we can all thrive.
            </p>
          </div>
        </div> -->
        <!-- //growth //community //excellence -->
      </section>
      <section class="max-w-7xl mx-auto"></section>
    </div>
    <!-- Threads -->
    <div id="threads" class="relative py-24">
      <div class="px-6 lg:px-10 relative z-[5]">
        <section class="relative max-w-7xl mx-auto fade-in">
          <p
            class="uppercase tracking-widest font-semibold text-woven-light-green"
          >
            Community-Driven
          </p>
          <h2
            class="my-4 text-4xl lg:text-5xl font-bold bg-gradient-to-r from-white via-woven-light-green to-woven-green inline-block text-transparent bg-clip-text"
          >
            Unfold The Possible With Threads
          </h2>
          <!-- <h2 class="text-5xl font-bold text-white">
            Staying ahead of industry trends.
          </h2> -->
          <p class="text-base lg:text-lg">
            Threads is Woven's innovative approach to solving our customers'
            most challenging problems. Each thread embodies our unique expertise
            and combine together to deliver the most cutting-edge solutions in
            today's ever-changing landscape.
          </p>
          <div
            :class="[
              'mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-x-10 gap-y-8 justify-items-center',
              {
                'max-h-[77rem] md:max-h-[37rem] overflow-hidden':
                  !showMoreThreads,
              },
            ]"
          >
            <template v-for="(thread, threadKey) in Threads" :key="threadKey">
              <router-link
                :to="{
                  name: 'thread',
                  params: { name: threadKey },
                }"
              >
                <div
                  class="flex flex-col max-w-[624px] h-full p-6 rounded-md bg-slate-900 hover:bg-slate-800/75"
                >
                  <SvgIcon
                    :name="thread.icon"
                    class="w-24 h-24 text-slate-50"
                  />
                  <div class="py-6 text-left">
                    <div
                      class="font-semibold text-xl lg:text-2xl text-slate-50"
                    >
                      {{ thread.name }}
                    </div>
                    <p class="pt-4">
                      {{ thread.description }}
                    </p>
                  </div>
                </div>
              </router-link>
            </template>
          </div>
          <template v-if="!showMoreThreads">
            <div
              class="inset-x-0 bottom-0 flex justify-center bg-gradient-to-t pt-32 pb-8 pointer-events-auto from-slate-950 absolute"
            >
              <button
                type="button"
                class="relative focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 text-sm text-white font-semibold h-12 px-6 rounded-lg flex items-center bg-slate-800 hover:bg-slate-700 pointer-events-auto"
                @click="toggleMoreThreads"
              >
                Discover More...
              </button>
            </div>
          </template>
        </section>
      </div>
      <!-- <div class="absolute top-0 brightness-[.33] w-screen">
        <div class="flex flex-col">
          <img
            class="h-full w-full"
            src="@/shared/assets/layered-waves-haikei-bottom1.svg"
          />
          <img
            class="h-full w-full"
            src="@/shared/assets/layered-waves-haikei-top1.svg"
          />
        </div>
      </div> -->
    </div>
    <!-- Solutions -->
    <div id="solutions" class="py-24 px-6 lg:px-10 overflow-x-hidden">
      <section class="max-w-7xl mx-auto fade-in">
        <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-8 gap-y-8 lg:gap-y-0">
          <div class="flex flex-col justify-center">
            <div>
              <p
                class="uppercase tracking-widest font-semibold text-woven-light-green"
              >
                Building Excellence
              </p>
              <h2 class="my-4 text-4xl lg:text-5xl font-bold text-white">
                Solutions that exceed our customers' needs.
              </h2>
            </div>
          </div>
          <div class="max-w-[624px] h-full">
            <!--- Image Container --->
            <div class="max-h-[351px] aspect-video">
              <img
                class="h-full brightness-75"
                src="@/shared/assets/platform_plus.png"
              />
            </div>
            <div class="pt-4 text-sm">Platform Plus</div>
          </div>
        </div>
        <div class="flex lg:justify-center mt-16">
          <div
            class="max-w-[768px] p-8 lg:px-12 lg:py-10 flex flex-col rounded-md bg-slate-900"
          >
            <div>
              <div class="font-semibold text-xl lg:text-2xl text-slate-50">
                Platform Plus
              </div>
              <div class="mt-4">
                <p>
                  Woven has built an implementation of industry-proven best
                  practices to support a flexible, multi-cloud system capable of
                  independently scaling commercial off-the-shelf, government
                  off-the-shelf, or open-source components.
                </p>
                <p class="pt-4">Its features include:</p>
                <ul class="pl-4 pt-2 list-disc">
                  <li>Tiered Storage Solutions for Cost Optimization</li>
                  <li>
                    NoSQL Document Database for Enterprise Data Cataloging
                  </li>
                  <li>
                    Scalable Search Including A Geospatial Analytics Engine
                  </li>
                  <li>Analysis Data Mesh</li>
                </ul>
              </div>
            </div>
            <div class="flex justify-center mt-8">
              <a
                href="mailto:product@wovensolutions.io"
                class="relative focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 text-sm text-white font-semibold h-12 px-6 rounded-lg flex items-center bg-slate-800 hover:bg-slate-700 pointer-events-auto"
              >
                <span class="mr-2.5">Request A Demo</span>
                <ArrowRightIcon class="h-4 w-4" />
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!-- Insights -->
    <template v-if="!loadingPosts && posts.length >= 3">
      <div class="px-6 lg:px-10 py-20">
        <section class="max-w-7xl mx-auto">
          <h2 class="mt-6 text-4xl lg:text-5xl font-bold text-white">
            Latest Insights
          </h2>
          <p class="mt-4 text-base lg:text-lg text-slate-300">
            Discover the latest insights, tips, and trends in our industry. Dive
            into our world of innovative ideas and learn more about what drives
            us.
          </p>
          <div
            class="mt-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-10 gap-y-8"
          >
            <BlogPostCard v-for="post in posts" :key="post.id" :post="post" />
          </div>
          <div class="flex justify-center mt-8">
            <router-link
              to="/blog"
              class="inline-flex items-center justify-center rounded-lg border py-[calc(theme(spacing.3)-1px)] px-[calc(theme(spacing.4)-1px)] border-woven-green text-woven-green"
            >
              <span class="mr-2.5">More Insights</span>
              <ArrowRightIcon class="h-4 w-4" />
            </router-link>
          </div>
        </section>
      </div>
    </template>
    <!-- Statement -->
    <div class="py-24 px-6 lg:px-10">
      <section class="max-w-[680px] mx-auto fade-in text-2xl lg:text-3xl">
        <p>
          Together we are building a
          <span class="font-semibold text-woven-light-green">community</span>
          of experts who embrace change. We are reshaping the industry through
          our desire to build
          <span class="font-semibold text-woven-light-green">excellence</span>,
          and fostering collective
          <span class="font-semibold text-woven-light-green">growth</span>
          where we can all thrive.
        </p>
      </section>
    </div>
    <!-- Careers -->
    <div id="careers" class="py-24 px-6 lg:px-10">
      <section class="max-w-7xl mx-auto flex justify-end fade-in">
        <div class="max-w-[850px]">
          <p
            class="uppercase tracking-widest font-semibold text-woven-light-green"
          >
            Grow With Us
          </p>
          <p
            class="my-4 text-4xl leading-tight lg:text-5xl lg:leading-tight font-bold text-white flex flex-col space-y-4"
          >
            You belong here.
            <br />
            Be part of the next.
          </p>
          <p class="max-w-4xl text-base lg:text-lg">
            We're always looking for driven individuals who love to work hard
            and have fun. Our employees thrive in a team environment and enjoy
            solving challenging problems for our customers.
          </p>
          <div class="mt-12">
            <a
              href="mailto:careers@wovensolutions.io"
              class="relative w-fit cursor-pointer focus:outline-none focus:ring-2 focus:ring-slate-400 focus:ring-offset-2 h-14 px-6 rounded-lg flex items-center border border-woven-green text-woven-green pointer-events-auto"
            >
              <span class="mr-2.5">Start your journey at Woven</span>
              <ArrowRightIcon class="h-4 w-4" />
            </a>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { ArrowRightIcon } from "@heroicons/vue/24/outline";
import { ChevronDownIcon } from "@heroicons/vue/24/solid";
import { onMounted, ref } from "vue";
import { Threads } from "@/constants";
import { setupVideoOptimization } from "@/utils/videoOptimizer";
import { navigateToId } from "@/shared/helpers";
import SvgIcon from "@/components/SvgIcon.vue";
// import { useRouter } from "vue-router";
// import { onBeforeRouteUpdate } from "vue-router";
import BlogPostCard from "@/components/BlogPostCard.vue";
import { fetchPosts } from "@/shared/services/wpService";

export default {
  components: {
    ArrowRightIcon,
    ChevronDownIcon,
    BlogPostCard,
    SvgIcon,
  },
  setup() {
    const loadingPosts = ref(true);
    const posts = ref([]);

    const showMoreThreads = ref(false);

    const toggleMoreThreads = () => {
      showMoreThreads.value = true;
    };

    console.log("Object Entries: ", Object.entries(Threads));

    onMounted(async () => {
      try {
        await setupVideoOptimization();
      } catch (error) {
        console.error("Video optimization failed:", error);
      }

      try {
        const data = await fetchPosts(3);
        posts.value = data;
      } catch (error) {
        console.error("Failed to load posts:", error);
      } finally {
        loadingPosts.value = false;
      }

      const faders = document.querySelectorAll(".fade-in");
      const sliders = document.querySelectorAll(".slide-in");

      const appearOptions = {
        threshold: 0,
        rootMargin: "0px 0px -100px 0px",
      };

      const appearOnScroll = new IntersectionObserver(function (
        entries,
        appearOnScroll
      ) {
        entries.forEach((entry) => {
          if (!entry.isIntersecting) {
            return;
          } else {
            entry.target.classList.add("appear");
            appearOnScroll.unobserve(entry.target);
          }
        });
      },
      appearOptions);

      faders.forEach((fader) => {
        appearOnScroll.observe(fader);
      });

      sliders.forEach((slider) => {
        appearOnScroll.observe(slider);
      });
    });

    return {
      Threads,
      navigateToId,
      showMoreThreads,
      toggleMoreThreads,
      loadingPosts,
      posts,
    };
  },
};
</script>
<style lang="scss">
.fade-in {
  opacity: 0;
  transition: opacity 250ms ease-in;
}

.fade-in.appear {
  opacity: 1;
}

.from-left {
  transform: translateX(-50%);
}

.from-right {
  transform: translateX(50%);
}

.from-left,
.from-right {
  transition: opacity 250ms ease-in, transform 400ms ease-in;
  opacity: 0;
}

.from-left.appear,
.from-right.appear {
  transform: translateX(0);
  opacity: 1;
}
// .blurred {
//   @
// }
</style>
