<template>
  <div class="flex justify-start mt-4 mb-10">
    <div class="relative inline-block text-left">
      <div>
        <button
          id="menu-button"
          type="button"
          class="inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          aria-expanded="true"
          aria-haspopup="true"
          @click="isOpen = !isOpen"
        >
          {{ selectedCategory ? selectedCategory.name : "Filter By Thread" }}
          <svg
            class="-mr-1 ml-2 h-5 w-5 transform"
            :class="{ 'rotate-180': isOpen }"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
            fill="currentColor"
            aria-hidden="true"
          >
            <path
              fill-rule="evenodd"
              d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
              clip-rule="evenodd"
            />
          </svg>
        </button>
      </div>

      <transition
        enter-active-class="transition ease-out duration-100"
        enter-class="transform opacity-0 scale-95"
        enter-to-class="transform opacity-100 scale-100"
        leave-active-class="transition ease-in duration-75"
        leave-class="transform opacity-100 scale-100"
        leave-to-class="transform opacity-0 scale-95"
      >
        <div
          v-if="isOpen"
          class="origin-top-left absolute left-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="menu-button"
          tabindex="-1"
        >
          <div class="py-1" role="none">
            <button
              class="text-gray-700 block px-4 py-2 text-sm w-full text-left"
              role="menuitem"
              tabindex="-1"
              @click="clearCategory"
            >
              Clear Filter
            </button>
            <button
              v-for="category in categories"
              :key="category.id"
              class="text-gray-700 block px-4 py-2 text-sm w-full text-left"
              role="menuitem"
              tabindex="-1"
              @click="selectCategory(category)"
            >
              {{ category.name }}
            </button>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";

export default {
  props: {
    categories: {
      type: Array,
      required: true,
    },
  },
  emits: ["category-selected"],
  setup(props, { emit }) {
    const isOpen = ref(false);
    const selectedCategory = ref(null);

    const selectCategory = (category) => {
      selectedCategory.value = category;
      emit("category-selected", category);
      isOpen.value = false;
    };

    const clearCategory = () => {
      selectedCategory.value = null;
      emit("category-selected", null);
      isOpen.value = false;
    };

    return {
      isOpen,
      selectedCategory,
      selectCategory,
      clearCategory,
    };
  },
};
</script>

<style scoped></style>
