<template>
  <div v-if="loading" class="flex justify-center items-center h-screen">
    Loading...
  </div>
  <div
    v-else-if="error"
    class="flex justify-center items-center h-screen text-red-500"
  >
    Error: {{ error.message }}
  </div>
  <div v-else class="px-6 lg:px-10 py-20">
    <!-- <div v-else class="px-6 lg:px-10 py-20" @scroll.passive="onScroll"> -->
    <section class="max-w-7xl mx-auto">
      <h2 class="mt-6 text-4xl lg:text-5xl font-bold text-white">Insights</h2>
      <p class="mt-4 text-base lg:text-lg text-slate-300">
        Discover the latest insights, tips, and trends in our industry. Dive
        into our world of innovative ideas and learn more about what drives us.
      </p>
      <CategoryFilter
        :categories="categories"
        @category-selected="handleCategorySelected"
      />
      <div
        class="mt-10 grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-x-10 gap-y-8"
      >
        <template v-if="posts.length === 0">
          <div class="col-span-full text-center text-gray-500">
            No posts yet, check back soon!
          </div>
        </template>
        <BlogPostCard v-for="post in posts" :key="post.id" :post="post" />
      </div>
      <div v-if="loadingMore" class="flex justify-center items-center mt-6">
        Loading more...
      </div>
    </section>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import BlogPostCard from "@/components/BlogPostCard.vue";
import CategoryFilter from "@/components/CategoryFilter.vue";
import { fetchPosts, fetchCategories } from "@/shared/services/wpService";

export default {
  components: {
    BlogPostCard,
    CategoryFilter,
  },
  setup() {
    const posts = ref([]);
    const categories = ref([]);
    const loading = ref(true);
    const loadingMore = ref(false);
    const error = ref(null);
    const page = ref(1);
    const perPage = ref(15);
    const totalPages = ref(null);
    const selectedCategory = ref(null);

    const loadPosts = async (page, category = null) => {
      try {
        const { data, headers } = await fetchPosts(
          perPage.value,
          page,
          category
        );

        // Check total pages from response headers
        if (totalPages.value === null) {
          totalPages.value = parseInt(headers["x-wp-totalpages"], 10);
        }

        if (page === 1) {
          posts.value = data;
        } else {
          posts.value = [...posts.value, ...data];
        }
      } catch (err) {
        // Handle specific "rest_post_invalid_page_number" error
        if (
          err.response &&
          err.response.data.code === "rest_post_invalid_page_number"
        ) {
          console.warn("No more pages to load.");
        } else {
          error.value = err;
        }
      } finally {
        loading.value = false;
        loadingMore.value = false;
      }
    };

    const loadCategories = async () => {
      try {
        categories.value = await fetchCategories();
      } catch (err) {
        error.value = err;
      }
    };

    const handleCategorySelected = (category) => {
      selectedCategory.value = category;
      page.value = 1;
      totalPages.value = null;
      loadPosts(page.value, category ? category.id : null);
    };

    // Commented out the infinite scroll logic
    /*
    const onScroll = () => {
      const bottomOfWindow =
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight - 100;
      if (
        bottomOfWindow &&
        !loadingMore.value &&
        (totalPages.value === null || page.value < totalPages.value)
      ) {
        loadingMore.value = true;
        page.value++;
        loadPosts(
          page.value,
          selectedCategory.value ? selectedCategory.value.id : null
        );
      }
    };
    */

    onMounted(() => {
      loadPosts(page.value);
      loadCategories();
      // window.addEventListener("scroll", onScroll); // Commented out
    });

    return {
      posts,
      categories,
      loading,
      loadingMore,
      error,
      // onScroll, // Commented out
      handleCategorySelected,
    };
  },
};
</script>

<style lang="scss"></style>
