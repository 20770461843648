import { createApp } from "vue";
import upperFirst from "lodash/upperFirst";
import camelCase from "lodash/camelCase";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";
import "@/index.css";

//Base
const baseComponentFiles = import.meta.glob("./components/base/*.vue", {
  eager: true,
});

const app = createApp(App);

const componentFiles = [...Object.entries(baseComponentFiles)];

componentFiles.forEach(([path, m]) => {
  const componentName = upperFirst(
    camelCase(
      path
        .split("/")
        .pop()
        .replace(/\.\w+$/, "")
    )
  );
  app.component(componentName, m.default);
});

const setup = async () => {
  app.use(store).use(router).mount("#app");
};

setup();
