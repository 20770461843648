<template>
  <div v-if="loading" class="flex justify-center items-center h-screen">
    Loading...
  </div>
  <div
    v-else-if="error"
    class="flex justify-center items-center h-screen text-red-500"
  >
    Error: {{ error.message }}
  </div>
  <div v-else>
    <BlogPost :post="post" />
  </div>
</template>

<script>
import BlogPost from "@/components/BlogPost.vue";
import { fetchPostById } from "@/shared/services/wpService";

export default {
  components: {
    BlogPost,
  },
  data() {
    return {
      post: null,
      loading: true,
      error: null,
    };
  },
  async created() {
    await this.loadPost();
  },
  methods: {
    async loadPost() {
      const postId = this.$route.params.id;
      try {
        this.post = await fetchPostById(postId);
        this.loading = false;
      } catch (error) {
        this.error = error;
        this.loading = false;
      }
    },
  },
};
</script>
