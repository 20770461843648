<template>
  <router-link :to="{ name: 'blog-post', params: { id: post.id } }">
    <div
      class="h-full max-h-[450px] max-w-[600px] p-8 rounded-md bg-slate-800/60 hover:bg-slate-800/80 flex flex-col items-start"
    >
      <div class="w-full flex justify-center">
        <div class="flex items-center space-x-2">
          <span
            v-for="category in post.categories"
            :key="category.id"
            class="px-3 py-2 bg-woven-green text-black rounded-full text-sm"
          >
            {{ post._embedded["wp:term"][0][0].name }}
          </span>
        </div>
      </div>
      <h3
        class="font-semibold text-xl text-slate-50 mt-6 text-left"
        v-html="post.title.rendered"
      ></h3>
      <p class="text-sm text-slate-400 w-full mb-6 italic">
        {{ humanizedDate }}
      </p>
      <div class="line-clamp-5" v-html="post.excerpt.rendered"></div>
    </div>
  </router-link>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
export default {
  props: {
    post: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    humanizedDate() {
      return dayjs(this.post.date).fromNow();
    },
  },
};
</script>

<style></style>
