export const navigateToId = (event) => {
  event.preventDefault();

  console.log("event: ", event);

  const anchorUrl = event.currentTarget.href;
  const hashId = anchorUrl.substring(anchorUrl.indexOf("#") + 1);
  const element = document.getElementById(hashId);

  console.log("element: ", element);

  const headerOffset = 72;
  const elementPosition = element.getBoundingClientRect().top;
  const offsetPosition = elementPosition + window.scrollY - headerOffset;

  console.log("offSetPosition: ", offsetPosition);

  window.scrollTo({
    top: offsetPosition,
    behavior: "smooth",
  });

  //TODO: Remove after testing scrollTo hack
  //element.scrollIntoView({ behavior: "smooth" });
};
