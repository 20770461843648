<template>
  <div
    ref="topOfApp"
    class="min-h-screen bg-slate-950 text-slate-400"
    tabindex="-1"
  >
    <TheBanner />
    <main role="main">
      <router-view />
    </main>
    <TheFooter />
  </div>
</template>
<script>
import TheBanner from "@/components/TheBanner.vue";
import TheFooter from "@/components/TheFooter.vue";
export default {
  components: {
    TheBanner,
    TheFooter,
  },
  setup() {},
};
</script>
<style lang="scss">
@use "~inter-ui/default" with (
  $inter-font-display: swap,
  $inter-font-path: "~inter-ui/Inter (web)"
);
@use "~inter-ui/variable" with (
  $inter-font-display: swap,
  $inter-font-path: "~inter-ui/Inter (web)"
);

/* future font 

BWHaasGroteskWeb

neue haas grotesk 

*/

@include default.all;
@include variable.all;

html {
  font-family: "Inter", "system-ui";
  scroll-behavior: smooth;
}

@supports (font-variation-settings: normal) {
  html {
    font-family: "Inter var", "system-ui";
  }
}
</style>
