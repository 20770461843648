<template>
  <div class="py-4 px-6 lg:px-10">
    <section
      class="max-w-7xl mx-auto text-sm flex justify-between items-center"
    >
      <div>© {{ currentYear }} Woven Solutions. All Rights Reserved</div>
      <a
        href="https://www.linkedin.com/company/woven-solutions/"
        class="p-2 hover:text-white"
        target="_blank"
      >
        <SvgIcon name="linkedin" class="h-6 w-6" />
      </a>
    </section>
  </div>
</template>
<script>
import dayjs from "dayjs";
import SvgIcon from "@/components/SvgIcon.vue";
export default {
  components: {
    SvgIcon,
  },
  setup() {
    const currentYear = dayjs().format("YYYY");
    return {
      currentYear,
    };
  },
};
</script>
<style lang="scss"></style>
