import axios from "@/shared/axiosConfig";

const fetchPosts = async (numberOfPosts = 3, page = 1, category = null) => {
  try {
    let url = `posts?_embed=wp:term&per_page=${numberOfPosts}&page=${page}&categories_exclude=1375`;
    if (category) {
      url += `&categories=${category}`;
    }
    const response = await axios.get(url);
    return { data: response.data, headers: response.headers };
  } catch (error) {
    throw new Error("Error fetching posts", { cause: error });
  }
};

const fetchCategories = async () => {
  try {
    const response = await axios.get("categories?exclude=1,1375"); // Exclude the "General" and "Sandbox" categories, respectively
    return response.data;
  } catch (error) {
    throw new Error("Error fetching categories", { cause: error });
  }
};

const fetchPostById = async (postId) => {
  try {
    const response = await axios.get(`posts/${postId}?_embed=wp:term`);
    return response.data;
  } catch (error) {
    throw new Error(`Error fetching post with ID ${postId}`, error);
  }
};

export { fetchPosts, fetchCategories, fetchPostById };
