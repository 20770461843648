<template>
  <header>
    <nav class="fixed top-0 left-0 right-0 z-10 px-6 lg:px-10 py-6">
      <div class="flex justify-between items-center max-w-7xl mx-auto">
        <router-link to="/">
          <img
            class="h-[1.5rem]"
            src="@/shared/assets/woven_logo.png"
            alt="Link to homepage."
          />
        </router-link>
        <ul
          class="hidden lg:flex items-center gap-x-8 text-slate-300 font-medium"
        >
          <template v-for="navLink in navigationLinks" :key="navLink.name">
            <li class="cursor-pointer hover:text-white">
              <!-- If the navLink has a section, it's an internal anchor link -->
              <template v-if="navLink.section">
                <router-link
                  :to="{ name: 'home', hash: `#${navLink.section}` }"
                >
                  {{ navLink.name }}
                </router-link>
              </template>
              <!-- If the navLink has a link, it's an external route -->
              <template v-else-if="navLink.link">
                <router-link :to="{ path: navLink.link }">
                  {{ navLink.name }}
                </router-link>
              </template>
            </li>
          </template>
          <a
            href="mailto:contact@wovensolutions.io"
            class="px-4 py-2 rounded-lg bg-woven-green text-slate-900 font-medium text-sm"
          >
            Connect With Us
          </a>
        </ul>
        <!-- Mobile Menu -->
        <button
          type="button"
          class="block lg:hidden rounded-full text-slate-300 hover:text-white focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-offset-gray-800 focus:ring-white"
          data-cy-selector="mobileMenu"
          @click="openMobileMenu"
        >
          <span class="sr-only">Open settings menu</span>
          <Bars3Icon class="h-6 w-6" aria-hidden="true" />
        </button>
      </div>
    </nav>
  </header>
  <!-- Mobile Side Menu -->
  <TransitionRoot appear :show="isMobileMenuOpen" as="template">
    <Dialog as="div" class="block lg:hidden" @close="closeMobileMenu">
      <div class="fixed z-20 top-0 right-0 overflow-y-auto">
        <div class="h-screen">
          <TransitionChild
            as="template"
            enter="transition-opacity ease-linear duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <div class="fixed inset-0 bg-black/25 backdrop-blur-sm" />
          </TransitionChild>
          <TransitionChild
            as="template"
            enter="transform transition ease-in-out duration-300"
            enter-from="translate-x-full"
            enter-to="translate-x-0"
            leave="transform transition ease-in-out duration-300"
            leave-from="translate-x-0"
            leave-to="translate-x-full"
          >
            <DialogPanel
              class="relative min-h-full w-[min(20rem,calc(100vw-theme(spacing.10)))] text-left align-top transition-all transform bg-slate-900 shadow-lg"
            >
              <button
                type="button"
                class="absolute top-5 right-5 w-8 h-8 flex items-center justify-center text-slate-300 hover:text-white"
                tabindex="0"
                @click="closeMobileMenu"
              >
                <span class="sr-only">Close main menu</span
                ><XMarkIcon class="h-6 w-6" aria-hidden="true" />
              </button>
              <nav class="flex flex-col">
                <div class="px-8 py-6 border-b border-slate-700">
                  <router-link to="/">
                    <img
                      class="h-[1.5rem]"
                      src="@/shared/assets/woven_logo.png"
                      alt="Link to homepage."
                    />
                  </router-link>
                </div>
                <div class="px-8 py-6 border-b border-slate-700">
                  <div class="-my-2 items-start space-y-2">
                    <template
                      v-for="navLink in navigationLinks"
                      :key="navLink.name"
                    >
                      <!-- If the navLink has a section, it's an internal anchor link -->
                      <template v-if="navLink.section">
                        <router-link
                          class="block w-full py-2 font-medium text-slate-300 hover:text-white"
                          :to="{ name: 'home', hash: `#${navLink.section}` }"
                          @click="closeMobileMenu"
                        >
                          {{ navLink.name }}
                        </router-link>
                      </template>
                      <!-- If the navLink has a link, it's an external route -->
                      <template v-else-if="navLink.link">
                        <router-link
                          class="block w-full py-2 font-medium text-slate-300 hover:text-white"
                          :to="{ path: navLink.link }"
                          @click="closeMobileMenu"
                        >
                          {{ navLink.name }}
                        </router-link>
                      </template>
                    </template>
                  </div>
                </div>
                <div class="px-8 py-6">
                  <a
                    href="mailto:contact@wovensolutions.io"
                    class="block w-fit px-4 py-2 rounded-lg bg-woven-green text-slate-900 font-medium text-sm"
                  >
                    Connect With Us
                  </a>
                </div>
              </nav>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
<script>
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import {
  Dialog,
  DialogPanel,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";
import { Bars3Icon, XMarkIcon } from "@heroicons/vue/24/outline";
import { navigateToId } from "@/shared/helpers";

export default {
  components: {
    Dialog,
    DialogPanel,
    TransitionChild,
    TransitionRoot,
    Bars3Icon,
    XMarkIcon,
  },
  setup() {
    const route = useRoute();

    const navigationLinks = [
      {
        name: "Who We Are",
        section: "about",
      },
      {
        name: "Threads",
        section: "threads",
      },
      {
        name: "Solutions",
        section: "solutions",
      },
      {
        name: "Insights",
        link: "/blog",
      },
      {
        name: "Careers",
        section: "careers",
      },
    ];

    const isMobileMenuOpen = ref(false);
    const closeMobileMenu = () => {
      isMobileMenuOpen.value = false;
    };

    const openMobileMenu = () => {
      isMobileMenuOpen.value = true;
    };

    onMounted(() => {
      const header = document.querySelector("header");
      const nav = document.querySelector("nav");
      const headerObserver = new IntersectionObserver(
        (entries) => nav.classList.toggle("active", !entries[0].isIntersecting),
        { threshold: 0.85 }
      );
      headerObserver.observe(header);
    });

    return {
      navigateToId,
      route,
      navigationLinks,
      isMobileMenuOpen,
      closeMobileMenu,
      openMobileMenu,
    };
  },
};
</script>
<style lang="scss">
nav.active {
  @apply backdrop-blur bg-slate-900/50 shadow-sm shadow-slate-800;
}
</style>
