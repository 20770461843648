export function setupVideoOptimization() {
  return new Promise((resolve) => {
    function init() {
      const video = document.getElementById("background-video");
      if (!video) {
        console.warn("Video element not found");
        resolve();
        return;
      }

      const sources = video.getElementsByTagName("source");

      // Function to update the DOM with the chosen quality
      function updateQualityDisplay(quality) {
        let qualityDisplay = document.getElementById("video-quality-display");
        if (!qualityDisplay) {
          qualityDisplay = document.createElement("div");
          qualityDisplay.id = "video-quality-display";
          qualityDisplay.style.position = "fixed";
          qualityDisplay.style.bottom = "10px";
          qualityDisplay.style.right = "10px";
          qualityDisplay.style.backgroundColor = "rgba(0, 0, 0, 0.5)";
          qualityDisplay.style.color = "white";
          qualityDisplay.style.padding = "5px 10px";
          qualityDisplay.style.borderRadius = "5px";
          document.body.appendChild(qualityDisplay);
        }
        //qualityDisplay.textContent = `Video Quality: ${quality}`;
      }

      function selectAppropriateSource() {
        let selectedQuality = "low";

        // Check screen width and device pixel ratio
        const effectiveWidth = window.innerWidth * window.devicePixelRatio;
        if (effectiveWidth >= 1920) {
          selectedQuality = "high";
        } else if (effectiveWidth >= 1280) {
          selectedQuality = "medium";
        }

        // Check connection speed if available
        if ("connection" in navigator) {
          const connection = navigator.connection;
          const effectiveType = connection.effectiveType || "4g"; // Default to '4g' if not available
          const downlink = connection.downlink || 10; // Default to 10 Mbps if not available
          const rtt = connection.rtt || 50; // Default to 50ms if not available

          // Determine quality based on effective type
          if (effectiveType === "4g" || effectiveType === "wifi") {
            // Keep the selected quality based on screen size
          } else if (effectiveType === "3g") {
            selectedQuality = "medium";
          } else {
            selectedQuality = "low";
          }

          // Additional checks for downlink and rtt
          if (downlink < 2) {
            selectedQuality = "medium";
          } else if (downlink < 0.5) {
            selectedQuality = "low";
          }

          if (rtt > 300) {
            selectedQuality = "low";
          } else if (rtt > 150) {
            selectedQuality = "medium";
          }
        }

        // Set the appropriate source and log the selected quality
        let chosenSource;
        for (let source of sources) {
          if (source.dataset.quality === selectedQuality) {
            video.src = source.src;
            chosenSource = source;
            break;
          }
        }

        video.load();

        if (chosenSource) {
          console.log(
            `Selected video quality: ${selectedQuality}, source: ${chosenSource.src}`
          );
          updateQualityDisplay(selectedQuality);
        } else {
          console.warn("No appropriate video source found.");
        }
      }

      // Throttle the resize event handler
      let resizeTimeout;
      function handleResize() {
        clearTimeout(resizeTimeout);
        resizeTimeout = setTimeout(selectAppropriateSource, 150);
      }

      // Recheck on window resize and connection change
      window.addEventListener("resize", handleResize);

      if ("connection" in navigator) {
        navigator.connection.addEventListener(
          "change",
          selectAppropriateSource
        );
      }

      // Initial source selection
      selectAppropriateSource();

      resolve();
    }

    // If the DOM is already loaded, run init immediately
    if (document.readyState === "complete") {
      init();
    } else {
      // Otherwise, wait for the DOM to load
      window.addEventListener("load", init);
    }
  });
}
