import { createRouter, createWebHistory } from "vue-router";

import HomeView from "@/views/HomeView.vue";
import BlogView from "@/views/BlogView.vue";
import BlogPostView from "@/views/BlogPostView.vue";
// import CareersView from "@/views/CareersView.vue";
import ThreadView from "@/views/ThreadView.vue";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    meta: {
      title: "Woven",
    },
  },
  {
    path: "/blog",
    name: "blog",
    component: BlogView,
    meta: {
      title: "Blog | Woven",
    },
  },
  {
    path: "/blog/post/:id",
    name: "blog-post",
    component: BlogPostView,
    props: true,
  },
  // {
  //   path: "/careers",
  //   name: "careers",
  //   component: CareersView,
  //   meta: {
  //     title: "Careers | Woven",
  //   },
  // },
  {
    path: "/thread/:name",
    name: "thread",
    component: ThreadView,
    meta: {
      title: "Threads | Woven",
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0 };
    }
  },
});

router.beforeEach((to, from, next) => {
  // if (to.meta.viewType) {
  //   switch (to.meta.viewType) {
  //     case "attachment":
  //       document.title = to.params.url;
  //       break;
  //     case "search":
  //       document.title = to.params.name ? to.params.name : "Search";
  //       break;
  //   }
  // } else {
  //   document.title = to.meta.title ? to.meta.title : "";
  // }
  next();
});

export default router;
