export const Threads = Object.freeze({
  "software-development": {
    name: "Software Development",
    icon: "development",
    description:
      "In a world where technology evolves at lightning speed, our engineers build custom software that stands out for its innovative features and seamless user experience.",
  },
  "multi-cloud": {
    name: "Multi-Cloud Solutions",
    icon: "multicloud",
    description:
      "We leverage the unique strengths of modern cloud platforms to architect solutions that provide performance, security, flexibility, resilience at the optimal cost to meet our customers' needs.",
  },
  "cyber-defense": {
    name: "Cyber Defense",
    icon: "defense",
    description:
      "Cyber Defense is the sum of people, tools, and processes that safeguard and protect data, computers, networks, and applications. Woven personnel excel at bringing best practices and tools to our customers in the community.",
  },
  "devops-automation": {
    name: "DevOps Automation",
    icon: "devops",
    description:
      "DevOps is about implementing organizational changes through development, operations, and security teams. Our engineers build processes to bring people together to deliver high-quality products and services to customers.",
  },
  "user-experience": {
    name: "User Experience",
    icon: "ux",
    description:
      "We pride ourselves in understanding the holistic journey users traverse with products and services. We offer high-quality user experience by integrating proper engineering, marketing, and design principles into the software lifecycle.",
  },
  "artificial-intelligence": {
    name: "Artificial Intelligence",
    icon: "ai",
    description:
      "In today's data-rich world, we cut through the noise. We leverage cutting-edge AI/ML and advanced analytics to extract powerful insights that solve real business problems.",
  },
  "security-engineering": {
    name: "Security Engineering",
    icon: "security",
    description:
      "Our approach to Security Engineering is built on a foundation of security industry best practices and advanced technologies that keep your systems, applications and networks both secure and compliant.",
  },
  "customer-engagement": {
    name: "Customer Engagement",
    icon: "engagement",
    description:
      "Customers are at the core of our business. We foster strong, lasting relationships with our customers through delivered excellence by evoking our expertise in project management, product development, and strategic planning.",
  },
  "offensive-cyber": {
    name: "Offensive Cyber",
    icon: "offense",
    description:
      "Our experienced offensive cyber practitioners and cyber operation support professionals have been embedded with our nation's most cutting-edge mission space. We lead and develop real time cyber requirements for the most complex and demanding challenges.",
  },
});
