<template>
  <div class="pt-[72px] min-h-[calc(100vh-72px)] px-6 lg:px-10">
    <div class="max-w-6xl mx-auto">
      <template v-if="isValidThread(route.params.name)">
        <component :is="dynamicThreadComponent" />
      </template>
      <template v-else>
        <div class="py-36 text-center">
          <div class="pb-6 text-5xl text-woven-green font-medium">404</div>
          <div class="text-4xl">
            The page you're trying to access does not exist.
          </div>
        </div>
      </template>
    </div>
  </div>
</template>
<script>
import { computed, defineAsyncComponent } from "vue";
import { useRoute } from "vue-router";
import { Threads } from "@/constants";
export default {
  components: {},
  setup() {
    const route = useRoute();

    const isValidThread = (routeName) => {
      return Object.keys(Threads).find(
        (threadName) => threadName === routeName
      );
    };

    const dynamicThreadComponent = computed(() => {
      return defineAsyncComponent(() =>
        import(`../components/threads/${route.params.name}.vue`)
      );
    });

    return {
      route,
      isValidThread,
      dynamicThreadComponent,
    };
  },
};
</script>
<style lang="scss"></style>
