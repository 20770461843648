import { createStore } from "vuex";
// import testConsole from "./modules/test-console";
export default createStore({
  state: {
    loading: true,
    errors: null,
    delay: false,
    admin: false,
  },
  getters: {},
  actions: {},
  mutations: {},
  modules: {
    // testConsole,
  },
});
